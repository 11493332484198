import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  Switch,
  Route,
  withRouter,
  useLocation,
  // NavLink
} from "react-router-dom";
import Loading from "utils/loader";
import { useSelector, useDispatch } from "react-redux";
import Header from "components/material_components/material_header/header";
import SideBar from "components/material_components/sidebar";
import RhAdminHeader from "components/material_components/material_header/rh_admin_header";
import { useAuth0 } from "@auth0/auth0-react";
import { getUser, fetchReportTab, login } from "actions/auth";
import { fetchUserAssetGroupList } from "actions/asset_table";
import * as errorTypes from "action_types/error";
import * as authTypes from "action_types/auth";
import {
  showFailureModal,
  showHeaderWaring,
  hideHeaderWarning,
} from "actions/modals";
import { fetchDashboardStatus } from "actions/dashboard";
// import { WarningOutlined } from '@material-ui/icons';
// import moment from 'moment-timezone';
// import { CustomTooltip } from 'components/tooltip';
const Page404 = lazy(() => import("components/404"));

const Dashboard = lazy(() => import("containers/dashboard"));

const AttackSurfacePageLayout = lazy(() =>
  import("containers/attack_surface_page")
);
const UnauthenticatedRoute = lazy(() => import("utils/unauthenticated_route"));
const CodeExposurePageLayout = lazy(() => import("containers/code_exposure"));
const ReportsPageLayout = lazy(() => import("containers/reports"));
const SecurityRisksPageLayout = lazy(() => import("containers/security_risks"));
const NotificationPageLayout = lazy(() =>
  import("containers/notification_list_page.js")
);
const IssueTrackerPageLayout = lazy(() =>
  import("containers/issue_tracker_page")
);
const ProfilePage = lazy(() => import("containers/profile_page"));
const SettingsPage = lazy(() => import("containers/settings_page"));
const PrivateRoute = lazy(() => import("utils/private_route"));
const NotificationsPage = lazy(() => import("containers/notifications"));
const ExportReportPage = lazy(() => import("containers/export_report_page"));
const InsightsDashboard = lazy(() => import("containers/insights_dashboard"));

const SignUp = lazy(() => import("components/FTUE/signup"));
const SignIn = lazy(() => import("components/FTUE/signin"));
const OtpPage = lazy(() => import("components/FTUE/otp_page"));
const OrgVerification = lazy(() => import("components/FTUE/org_verification"));
const UserPostLoginForm = lazy(() => import("components/login_setup/userForm"));
const VerifyRH = lazy(() => import("components/FTUE/verifyRH"));
const ForgotPassword = lazy(() => import("components/FTUE/forgotPassword"));
const ResetPassword = lazy(() => import("components/FTUE/resetPassword"));
const RhAdminUsersPage = lazy(() => import("containers/rh_users_page"));
const RhAdminOrganisationsPage = lazy(() =>
  import("containers/rh_organisations_page")
);
const RhScanDetails = lazy(() => import("containers/scan_details"));
const RhPrivateRoute = lazy(() => import("utils/rh_private_route"));
const NetworkChart = lazy(() => import('containers/network_graph'))

const RhSecurityRisksPageLayout = lazy(() =>
  import("containers/rh_security_risks_page")
);
const RhDataleaksPageLayout = lazy(() =>
  import("containers/rh_dataleaks_page")
);
const RhAssetExposurePageLayout = lazy(() =>
  import("containers/rh_asset_exposure_page")
);
const RhAddAssetsPage = lazy(() => import("containers/add_assets_page"));

const RhAddIssuesPage = lazy(() => import("containers/add_issues_page"));
const SkipInventoryPage = lazy(() => import("containers/skip_inventory"));
const RhUpdateIssuesActivePage = lazy(() =>
  import("containers/update_issue_active_page")
);
const RhUpdateAssetsActivePage = lazy(() =>
  import("containers/update_assets_active")
);
const RhUpdateDataleaksActivePage = lazy(() =>
  import("containers/update_dataleaks_active")
);
const RhAddDataleaksPage = lazy(() => import("containers/add_dataleaks_page"));
const RhAddThirdPartyAsset = lazy(() => import("containers/add_thirdParty_asset"));

const RhSettingsPageLayout = lazy(() => import("containers/rh_settings_page"));

const RhChildScansPageLayout = lazy(() => import("containers/rh_child_scans"));

const Routes = (props) => {
 
  
  const location = useLocation();
  const dispatch = useDispatch();
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const authenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const report_tabs = useSelector(
    (state) => state.dashboard.REPORTS_TOP_LEVEL
  );
  const setupFinished = localStorage.getItem("dashboardStatus")
    ? JSON.parse(localStorage.getItem("dashboardStatus")).setup_finished
    : true;
  const role = useSelector((state) => state.authentication.role);
  const isLoading = useSelector((state) => state.authentication.loading);
  const refresh = useSelector((state) => state.authentication.refresh);
  const sidebarCollapsed = useSelector(
    (state) => state.modals.sidebarCollapsed
  );
  const warningVisible = useSelector((state) => state.modals.warningVisible);
  const [showSidebar, setShowSidebar] = useState(false);
  const handleBeforeUnload = (event) => {
    // Function you want to call before page refresh

    // Example: Prevent page reload (this adds a confirmation dialog in most browsers)
    // event.preventDefault();
    // event.returnValue = ""; // Some browsers require this to trigger a confirmation dialog.
  };
  useEffect(() => {
    let params = "";
    params = new URLSearchParams(location.search);
    if(params.get("error")){
      localStorage.setItem(
        "errorCode",
        params.get("error") ? params.get("error") : ""
      );
      localStorage.setItem(
        "errorDescription",
        params.get("error_description") ? params.get("error_description") : ""
      );
    }
    if(location.pathname !== "/login"){
      dispatch({
        type:"REFRESH_PAGE",
        refresh:true
      })
      if(localStorage.getItem("role") && localStorage.getItem("userProfile")){
          const data = JSON.parse(localStorage.getItem("userProfile"));
              const userProfile = {
                name: data.full_name,
                email: data.email,
                role: data.role,
                id: data.id,
                mfa_status: data.mfa_status,
                organisation: data.org_name,
                scope: data.scope ? data.scope : "",
              };
              if(location.pathname === "/historical-downloads"){
                dispatch(fetchReportTab(props.history))
              }
              dispatch({
                type: 'LOGIN_USER',
                isAuthenticated: true,
              });
              dispatch({
                type:"REFRESH_PAGE",
        refresh:false
              });
              dispatch({
                type: authTypes.GET_USER_PROFILE_SUCCESS,
                payload: userProfile,
              });
        }

     
    }
    params = new URLSearchParams(location.search);
    if(params.get("code") !== localStorage.getItem(
      "authCode") && params.get("code") && location.pathname !== "/settings/integrations") {
        dispatch(login({code:params.get("code")},props.history))
        localStorage.setItem(
          "authCode",
          params.get("code") ? params.get("code") : ""
        );
    }
    if(location.pathname === "/reports"){
      
      dispatch({
        type: 'REPORT_TAB_SUCCESS',
        REPORTS_SUB_SECTIONS: {
          "DATA_LEAKS": "DISABLED",
          "REPORT_PDF": "DISABLED",
          "WEB_ENDPOINTS": "DISABLED",
          "ASSET_EXPOSURE": "DISABLED",
          "SECURITY_RISKS": "DISABLED",
          "ASSET_INVENTORY": "DISABLED",
          "THIRD_PARTY_ASSETS": "DISABLED"
        },
        REPORTS_TOP_LEVEL: "ENABLED",
      });
    }
   
    if (
      location.pathname === "/settings/integrations" ||
      location.pathname === "/user/login-form" ||
      location.pathname === "/settings/billing"
    ) {
      params = new URLSearchParams(location.search);
      localStorage.setItem(
        "slackCode",
        params.get("code") ? params.get("code") : ""
      );
      localStorage.setItem(
        "pagerdutyCode",
        params.get("config") ? params.get("config") : ""
      );
      if (params.get("success") || params.get("canceled")) {
        if (params.get("success")) {
          localStorage.setItem(
            "success_billing_url",
            params.get("success")
          );
        } else {
          localStorage.setItem("cancel_billing_url", params.get("canceled"));
        }
      }
    } else if (location.pathname === "/") {
      params = new URLSearchParams(location.search);
      if (params.get("success")) {
        localStorage.setItem(
          "success_billing_url",
          params.get("success")
        );
      }
    }
    const getUserToken = async () => {
      const search = location.pathname === "/" ? "" : location.search;
      const path = `${location.pathname}`;
      const store = window.localStorage;
      let url = "";
      let prevUrl = "";
      url = store.getItem("url");
      store.setItem("prevUrl", url === '/login' ? '/' : url);
      let prevSearch = "";
      prevSearch = store.getItem("search");
      store.setItem("prevSearch", prevSearch);
      store.setItem("url", path);
      store.setItem("search", search);
      prevUrl = store.getItem("prevUrl");
      let prevUrlSearch = "";
      if (props.history.location.pathname !== "/invite/") {
        sessionStorage.removeItem("warningHiddenManually");
        // setTimeout(async () => {
        //   if (isAuthenticated) {
        //     const accessToken = await getAccessTokenSilently({
        //       audience,
        //     });
        //     if (accessToken) {
        //       // localStorage.setItem("token", accessToken);
        //       Cookies.set("token", accessToken, {
        //         secure: true,   // Only sent over HTTPS
        //         sameSite: 'Strict', // Helps prevent CSRF attacks
        //         domain: window.location.hostname, });

        //     }
        //   }
        // }, 3000)
        setTimeout(async () => {
          // if (location.pathname === "/") {
          //   params = new URLSearchParams(location.search);
          //   if (params.get("code")) {
          //     props.history.push('/')
          //   }
          // }
          

          if (
            prevUrl === "/user/admin-confirmation" ||
            prevUrl === "/user/org-details" ||
            prevUrl === "/login" ||
            prevUrl === "/invite/"
          ) {
            localStorage.setItem("location", JSON.stringify("/"));
          } else if (url !== "/") {
            localStorage.setItem("location", JSON.stringify(`${url}${search}`));
            prevUrlSearch = "";
          } else {
            prevUrlSearch = store.getItem("prevSearch");
            localStorage.setItem(
              "location",
              JSON.stringify(`${prevUrl}${search}`)
            );
          }
          // try {
          //   if (
          //     Cookies.get("token") &&
          //     localStorage.getItem("userProfile")
          //   ) {
          //     const data = JSON.parse(localStorage.getItem("userProfile"));
          //     const userProfile = {
          //       name: data.full_name,
          //       email: data.email,
          //       role: data.role,
          //       id: data.id,
          //       mfa_status: data.mfa_status,
          //       organisation: data.org_name,
          //       scope: data.scope ? data.scope : "",
          //     };
          //     if(location.pathname === "/historical-downloads"){
          //       dispatch(fetchReportTab(props.history))
          //     }
          //     dispatch({
          //       type: authTypes.GET_USER_PROFILE_SUCCESS,
          //       payload: userProfile,
          //     });
          //     if (data.role === 'USER' || data.role === 'SUBSIDIARY' || data.role === 'VENDOR') {
          //       dispatch({
          //         type: "FETCH_ALL_USER_ASSET_GROUP_LIST_SUCCESS",
          //         data: data.asset_groups.map(el => {
          //           return {
          //             id: el.asset_group_id,
          //             group_name: el.asset_group
          //           }
          //         }),
          //       });
          //     }
          //     if (
          //       data.role === "ORG_PENDING" ||
          //       data.role === "PENDING" ||
          //       data.role === "VERIFIED" ||
          //       data.role === "ADMIN" ||
          //       data.role === "MANAGER"
          //     ) {
          //       if (
          //         data.role === "ORG_PENDING" ||
          //         data.role === "PENDING" ||
          //         data.role === "VERIFIED"
          //       ) {
          //         dispatch(
          //           getUser(props.history, Cookies.get("token"))
          //         );
          //       }
          //       if (data.role === "ADMIN" || data.role === "MANAGER") {
          //         dispatch(fetchDashboardStatus(props.history));
          //       }
          //     }
          //     const setup_finished = localStorage.getItem("dashboardStatus")
          //       ? JSON.parse(localStorage.getItem("dashboardStatus"))
          //         .setup_finished
          //       : true;
          //     const currunt_location = localStorage.getItem("url")
          //       ? localStorage.getItem("url")
          //       : "/";
          //     let newPath = setup_finished
          //       ? currunt_location && !data.role.startsWith("rh")
          //         ? currunt_location
          //         : data.role.startsWith("rh") && currunt_location === "/"
          //           ? "/admin/orgs"
          //           : currunt_location
          //       : "/user/login-form";
          //     // setTimeout(() => {
          //     //   props.history.push(`${newPath}${prevUrlSearch}`);
          //     // }, 0);
          //     dispatch({
          //       type:"REFRESH_PAGE",
          //       refresh:false
          //     })
          //   } else {
          //     const accessToken = await getAccessTokenSilently({
          //       audience,
          //     });
          //     // localStorage.setItem("token", accessToken);
          //     Cookies.set("token", accessToken, {
          //       secure: true,   // Only sent over HTTPS
          //       sameSite: 'Strict', // Helps prevent CSRF attacks
          //       domain: window.location.hostname, });
          //     if(location.pathname === "/historical-downloads"){
          //       dispatch(fetchReportTab(props.history))
          //     }
          //     if (accessToken) {
          //       localStorage.setItem("tour", 1);
          //       dispatch(getUser(props.history, accessToken));
          //     }
          //   }
          //   dispatch({
          //     type:"REFRESH_PAGE",
          //     refresh:false
          //   })
          // } catch (e) {
          //   dispatch({
          //     type:"REFRESH_PAGE",
          //     refresh:false
          //   })
          //   if (e.message.startsWith("blacklisted_email")) {
          //     dispatch({
          //       type: errorTypes.CATCH_ERROR,
          //       errorInfo: {
          //         errorResponse: e.message.slice(19),
          //         errorMessage: e.message.slice(19),
          //         errorKeys: [],
          //         errorCode: 400,
          //       },
          //     });
          //     dispatch(showFailureModal());
          //   } else {
          //     props.history.push("/");
          //   }
          // }
        }, 2000);
      }
    };
    if(location.pathname !== "/login") {
      getUserToken();
    }
    const dashboardStatus = localStorage.getItem("dashboardStatus")
      ? JSON.parse(localStorage.getItem("dashboardStatus")).setup_finished
      : true;
    const data = JSON.parse(localStorage.getItem("userProfile"));
    if (!dashboardStatus && data.role === "ADMIN") {
      props.history.push("/user/login-form");
    }
    // eslint-disable-next-line
  }, []);

  // const unvalidated = useSelector((state) => state.dashboard.unvalidated);
  // const killScan = useSelector((state) => state.dashboard.is_killed_scan);
  // const dashboardScanStatus = localStorage.getItem('dashboardStatus');
  // eslint-disable-next-line
  const [isAccountReady, setIsAccountReady] = useState(false);
  // const [isUnvalidated, setIsUnvalidated] = useState(false);
  // const [isKilledScan, setIsKilledScan] = useState(false);

  useEffect(() => {
    const updateHead = async () => {
      let locationChange = location.pathname;
      if (
        locationChange === "/security-risks" ||
        locationChange === "/code-exposures" ||
        locationChange === "/attack-surface" ||
        locationChange === "/issue-tracker/kanban" ||
        locationChange === "/settings"
      ) {
        await dispatch(fetchDashboardStatus(props.history));
      }

      const dashboardStatus = localStorage.getItem("dashboardStatus");
      const warningHiddenManually = sessionStorage.getItem(
        "warningHiddenManually"
      );
      if (dashboardStatus !== null) {
        const parsed = JSON.parse(dashboardStatus);
        if (parsed.is_unvalidated || parsed.is_killed_scan) {
          if (!warningHiddenManually || warningHiddenManually === "false") {
            dispatch(showHeaderWaring());
          } else if (warningHiddenManually === "true") {
            dispatch(hideHeaderWarning());
          }
        }
      }
    };
    updateHead();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsAccountReady(
      role !== "PENDING" && role !== "ORG_PENDING" && role !== "VERIFIED"
    );
    if (
      authenticated &&
      setupFinished &&
      !role.startsWith("rh") &&
      role !== "ORG_PENDING" &&
      role !== "VERIFIED" &&
      location.pathname !== "/user/login-form" &&
      role !== "PENDING" &&
      location.pathname !== "/login"
    ) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }, [role, authenticated, location.pathname, setupFinished]);

  // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="App">
      { refresh && isLoading ? (
        <div>
          <div style={{ width: "100%" }}>
            <Loading
              type="Triangle"
              height="50"
              width="50"
              loaderStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                background: "var(--color-bodyBG)",
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          {role ? (
            authenticated &&
              role.startsWith("rh") &&
              role !== "PENDING" &&
              role !== "ORG_PENDING" &&
              role !== "VERIFIED" ? (
              <RhAdminHeader />
            ) : null
          ) : null}
          {role &&  !role.startsWith("rh") && showSidebar ? <Header /> : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {role &&  !role.startsWith("rh") ? (
              showSidebar ? (
                <div
                  style={{
                    position: "fixed",
                    top: warningVisible ? 112 : 64,
                    zIndex: 1000,
                  }}
                >
                  <div className="top-round">
                    <div className="top-round-back">

                    </div>
                  </div>
                  <SideBar />
                </div>
              ) : null
            ) : null}
            <div
              className="main_screen"
              id="main"
              style={{
                marginTop: showSidebar
                  ? warningVisible
                    ? 112
                    : 64
                  : location.pathname === "/user/login-form" ||
                    location.pathname === "/user/email-verification" ||
                    location.pathname === "/user/org-verification"
                    ? 0
                    : role.startsWith("rh") ? 80 : 0,
                marginLeft: role.startsWith("rh") ? 0 : showSidebar 
                  ? sidebarCollapsed
                    ? 80
                    : 270
                  : 0,
                flexDirection: "column",
                width: `calc(100% / ${sidebarCollapsed ? 80 : 270})`,
                height: authenticated && showSidebar ? warningVisible ? '87vh' : '93vh' : '100vh',
                background: 'var(--color-bodyBG)',
                overflow: 'auto',
              }}
            >
              <Suspense
                fallback={
                  <div style={{ width: "100%", }}>
                    <Loading
                      type="Triangle"
                      height="50"
                      width="50"
                      loaderStyle={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: `calc(100vh - ${showSidebar
                          ? warningVisible
                            ? '112px'
                            : '64px' : '0px'})`,
                        background: "var(--color-bodyBG)",
                      }}
                    />
                  </div>
                }
              >
                {/* {role ? null : (
                  <div>
                    <Switch>
                      <Route exact path="/" component={Dashboard} />
                    </Switch>
                  </div>
                )} */}
                {!authenticated ? (
                  <div>
                    <Switch>
                      <UnauthenticatedRoute
                        exact
                        path="/"
                        component={Dashboard}
                      />
                      <Route exact path="/login" component={SignIn} />
                      <Route
                        exact
                        path="/api/v1/password/reset/confirm/:id/:token"
                        component={ResetPassword}
                      />
                      <Route
                        exact
                        path="/invite/:id?"
                        component={ResetPassword}
                      />
                    </Switch>
                  </div>
                ) : (
                  <Switch>
                    {/* <Route exact path="/" component={Dashboard} /> */}
                    <Route exact path="/login" component={SignIn} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route
                      exact
                      path="/forgot-password"
                      component={ForgotPassword}
                    />
                    <Route
                      exact
                      path="/invite/:id?"
                      component={ResetPassword}
                    />
                    <Route
                      exact
                      path="/api/v1/password/reset/confirm/:id/:token"
                      component={ResetPassword}
                    />
                    <PrivateRoute
                      exact
                      path="/user/email-verification"
                      component={OtpPage}
                    />
                    <PrivateRoute
                      exact
                      path="/user/org-verification"
                      component={OrgVerification}
                    />
                    <Route
                      exact
                      path="/user/login-form"
                      component={UserPostLoginForm}
                    />
                    <PrivateRoute exact path="/" component={Dashboard} />
                    <PrivateRoute exact path="/insights" component={InsightsDashboard} />
                    <PrivateRoute
                      exact
                      path="/user/admin-confirmation"
                      component={VerifyRH}
                    />
                    <PrivateRoute
                      exact
                      path="/user/profile"
                      component={ProfilePage}
                    />
                    <PrivateRoute path="/settings" component={SettingsPage} />
                    {/* <PrivateRoute path="/graph_db" component={NetworkChart} /> */}

                    <PrivateRoute
                      // exact
                      path="/attack-surface"
                      component={AttackSurfacePageLayout}
                    />
                    <PrivateRoute
                      path="/code-exposures"
                      component={CodeExposurePageLayout}
                    />
                    <PrivateRoute
                      path="/security-risks"
                      component={SecurityRisksPageLayout}
                    />
                    <PrivateRoute
                      path="/notification"
                      component={NotificationPageLayout}
                    />
                    <PrivateRoute
                      path="/issue-tracker"
                      component={IssueTrackerPageLayout}
                    />

                    <PrivateRoute
                      exact
                      path="/notifications"
                      component={NotificationsPage}
                    />
                    {report_tabs === 'ENABLED' ?
                      <PrivateRoute
                        exact
                        path="/historical-downloads"
                        component={ExportReportPage}
                      />
                      : null
                    }
                      <PrivateRoute
                        path="/reports"
                        component={ReportsPageLayout}
                      />

                    <RhPrivateRoute
                      exact
                      path="/admin/users"
                      component={RhAdminUsersPage}
                    />
                    <RhPrivateRoute
                      path="/admin/rh/settings"
                      component={RhSettingsPageLayout}
                    />

                    <RhPrivateRoute
                      exact
                      path="/admin/orgs"
                      component={RhAdminOrganisationsPage}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans"
                      component={RhChildScansPageLayout}
                    />
                    <RhPrivateRoute
                      exact
                      path="/scans/:id"
                      component={RhScanDetails}
                    />

                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/security-risks"
                      component={RhSecurityRisksPageLayout}
                    />

                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/rh-dataleaks"
                      component={RhDataleaksPageLayout}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/rh-asset-exposure"
                      component={RhAssetExposurePageLayout}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/add-assets"
                      component={RhAddAssetsPage}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/add-issues"
                      component={RhAddIssuesPage}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/skip-inventory"
                      component={SkipInventoryPage}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/update-issues-active"
                      component={RhUpdateIssuesActivePage}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/update-assets-active"
                      component={RhUpdateAssetsActivePage}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/update-dataleaks-active"
                      component={RhUpdateDataleaksActivePage}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/add-dataleaks"
                      component={RhAddDataleaksPage}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/add-third-party-asset"
                      component={RhAddThirdPartyAsset}
                    />
                    <RhPrivateRoute
                      exact
                      path="/admin/orgs/:id/scans/update-dataleaks"
                      component={RhUpdateDataleaksActivePage}
                    />
                    <RhPrivateRoute
                      exact
                      path="/api/v1/silk"
                      component={() => {
                        window.location.href =
                          "https://staging.redhuntlabs.com/api/v1/silk/";
                        return null;
                      }}
                    />
                    <PrivateRoute component={Page404} />
                  </Switch>
                )}
              </Suspense>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Routes);
